import React from "react";

import webSiteLogo from "../../Asset/Imgs/alausra.png";

const LoaderPage = () => {
	return (
		<div
			className='fixed top-0 left-0 w-screen h-screen bg-white  flex justify-center items-center '
			style={{ zIndex: "1111" }}>
			<img src={webSiteLogo} alt='' className='loading-logo' />
		</div>
	);
};

export default LoaderPage;
