import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const ContactUsFormThunk = createAsyncThunk(
	"ContactUsForm",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.post(
				"https://api.alausra.sa/public/contact_us",
				arg
			);

			return res?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
