import React from "react";
import GlobalTitleBox from "../GlobalTitleBox/GlobalTitleBox";
import { AboutUsSectionThunk } from "../../RTK/Thunk/AboutUsSectionThunk";
import { useDispatch, useSelector } from "react-redux";

import webSiteLogo from "../../Asset/Imgs/alausra.png";

const AboutUsSection = () => {
	const dispatch = useDispatch();
	const { data } = useSelector((state) => state.AboutUsSectionReducer);

	React.useEffect(() => {
		dispatch(AboutUsSectionThunk());
	}, []);
	return (
		<section className=' global_Padding container '>
			<GlobalTitleBox title={data?.title} />

			<section className='flex flex-col-reverse md:flex-row justify-start items-start gap-5'>
				<div
					className='w-full md:w-1/2 '
					dangerouslySetInnerHTML={{ __html: data.text }}
				/>

				<div className='w-full md:w-1/2 flex justify-center items-center rounded-lg relative about-us-img-box'>
					{data?.file?.includes(".mp4" || ".avi" || ".mov" || ".mkv") ? (
						<video
							className='rounded-lg'
							src={data?.file}
							controls
							poster={webSiteLogo}
						/>
					) : (
						<img className='rounded-lg' src={data?.file} alt='' />
					)}
				</div>
			</section>
		</section>
	);
};

export default AboutUsSection;
