import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const SectionsOfPagesThunk = createAsyncThunk(
	"SectionsOfPagesThunk",
	async (arg, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.get(
				`https://api.alausra.sa/api/public/pages/${arg}/sections`
			);

			return res?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
