import React, { useEffect } from "react";

import { FaMapLocationDot } from "react-icons/fa6";
import { IoIosMail, IoIosArrowBack } from "react-icons/io";
import whiteLogo from "../../Asset/Imgs/alausraWhite.png";

import { FaPhoneVolume, FaTiktok } from "react-icons/fa";
import { FaFacebookF } from "react-icons/fa6";
import { PiInstagramLogo } from "react-icons/pi";
import { RiSnapchatFill } from "react-icons/ri";
import { FaXTwitter } from "react-icons/fa6";
import { FiExternalLink } from "react-icons/fi";
import { useDispatch, useSelector } from "react-redux";
import { ContactInfoThunk } from "../../RTK/Thunk/ContactInfoThunk";
import { SocialLinksThunk } from "../../RTK/Thunk/SocialLinksThunk";
import { SiteLogoThunk } from "../../RTK/Thunk/SiteLogoThunk";
import { PagesThunk } from "../../RTK/Thunk/PagesThunk";

const Footer = () => {
	const dispatch = useDispatch();
	const { contactInfo } = useSelector((state) => state.ContactInfoReducer);
	const { socialLinks } = useSelector((state) => state.SocialLinksReducer);
	const { siteLogo } = useSelector((state) => state.SiteLogoReducer);
	const { websiteMapPages, quickLinksPages } = useSelector(
		(state) => state.PagesReducer
	);

	const getDataRef = React.useRef(true);
	React.useEffect(() => {
		if (getDataRef.current) {
			getDataRef.current = false;
			dispatch(ContactInfoThunk());
			dispatch(SocialLinksThunk());
			dispatch(SiteLogoThunk());
		}
	}, [getDataRef]);
	// social media links
	const socialLinksItems = socialLinks?.map((item) => (
		<li key={item}>
			<a
				className=' rounded-full border-[1px] group flex justify-center items-center !w-[34px] !h-[34px] p-1 group hover:border-color_03  border-color_06'
				href={item}>
				{item?.includes("facebook") ? (
					<FaFacebookF className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_03  text-color_06 !text-[20px]' />
				) : item?.includes("twitter") ? (
					<FaXTwitter className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]  group-hover:text-color_03  text-color_06 !text-[20px]' />
				) : item?.includes("snapchat") ? (
					<RiSnapchatFill className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]   group-hover:text-color_03  text-color_06 !text-[20px]' />
				) : item?.includes("tiktok") ? (
					<FaTiktok className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s]   group-hover:text-color_03  text-color_06 !text-[20px]' />
				) : item?.includes("instagram") ? (
					<PiInstagramLogo className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s] group-hover:text-color_03  text-color_06 !text-[20px]' />
				) : (
					<FiExternalLink className=' group-hover:!text-[23px] !w-[50px]	 transition-[0.5s] group-hover:text-color_03  text-color_06 !text-[20px]' />
				)}
			</a>
		</li>
	));

	/** pages */
	const websiteMapPagesLinks = websiteMapPages?.map((page) => (
		<div className='group ' key={page?.id}>
			<h6 className='flex justify-start items-center gap-[3px]  group-hover:mr-[8px] text-color_04 hover:text-color_03 transition-[0.3s]  cursor-pointer  text-[16px] font-medium'>
				<span>
					<IoIosArrowBack />
				</span>
				<span>{page?.name}</span>
			</h6>
			<div className='group-hover:w-[95%] transition-[0.3s] w-0 h-[1px] mr-[8px] bg-color_04'></div>
		</div>
	));

	const quickLinksPagesLinks = quickLinksPages?.map((page) => (
		<div className='group ' key={page?.id}>
			<h6 className='flex justify-start items-center gap-[3px]  group-hover:mr-[8px] text-color_04 hover:text-color_03 transition-[0.3s]  cursor-pointer  text-[16px] font-medium'>
				<span>
					<IoIosArrowBack />
				</span>
				<span>{page?.name}</span>
			</h6>
			<div className='group-hover:w-[95%] transition-[0.3s] w-0 h-[1px] mr-[8px] bg-color_04'></div>
		</div>
	));

	return (
		<footer className=' pb-[10px] bg-main_color_08 h-full bg-color_02 pt-[50px]'>
			<div className='container '>
				<div className=' flex max-lg:flex-col-reverse justify-between gap-[50px] items-start'>
					<div className=' flex gap-[50px] max-lg:flex-col justify-start flex-1 items-start'>
						<div className=' flex-1  '>
							<div
								style={{ direction: "rtl" }}
								className=' box-info  gap-[8px]  flex flex-col justify-start items-start text-start'>
								<div className='box-img w-[145px]  h-[80px]'>
									<img
										src={whiteLogo}
										alt=''
										className=' w-full h-full object-contain'
									/>
								</div>
								<ul className=' link_footer flex justify-start items-center gap-[10px] mt-2'>
									{socialLinksItems}
								</ul>
							</div>
						</div>
						<div className='flex-1 '>
							<div
								style={{ direction: "rtl" }}
								className=' box-info   gap-[5px]  flex flex-col justify-start items-start text-start'>
								<h4 className='text-color_04 transition-[0.3s] font-medium text-xl mb-3'>
									خريطة الموقع
								</h4>
								{websiteMapPagesLinks}
							</div>
						</div>
						<div className='flex-1  '>
							<div
								style={{ direction: "rtl" }}
								className=' box-info w-full  gap-[5px]  flex flex-col justify-start items-start text-start'>
								<h4 className='text-color_04 transition-[0.3s] font-medium text-xl mb-3'>
									الروابط السريعة
								</h4>
								{quickLinksPagesLinks}
							</div>
						</div>
						<div className='flex-1  '>
							<div
								style={{ direction: "rtl" }}
								className=' box-info w-full  gap-[5px]  flex flex-col justify-start items-start text-start'>
								<h4 className='text-color_04 transition-[0.3s] font-medium text-xl mb-3'>
									تواصل معنا{" "}
								</h4>
								<div className='group '>
									<h6 className='flex justify-start items-center gap-[5px] text-color_04 text-[16px] font-medium'>
										<span>
											<FaMapLocationDot />
										</span>
										<span>{contactInfo?.address}</span>
									</h6>
								</div>
								<div className='group '>
									<h6 className='flex justify-start items-center gap-[5px] text-color_04 text-[16px] font-medium'>
										<span>
											<FaPhoneVolume />
										</span>
										<span>{contactInfo?.phone}</span>
									</h6>
								</div>
								<div className='group '>
									<h6 className='flex justify-start items-center gap-[5px] text-color_04 text-[16px] font-medium'>
										<span>
											<IoIosMail />
										</span>
										<span>{contactInfo?.email}</span>
									</h6>
								</div>
							</div>
						</div>
					</div>
				</div>

				<div className=' w-full h-[1px] mt-10 mb-3 bg-color_11'></div>
				<h6 className='flex w-full justify-center items-center gap-[5px] text-color_03  text-[14px] font-normal'>
					<span>
						تم التنفيذ من قبل
						<a
							className='text-[#d0d0d0c2] hover:text-color_04 font-bold'
							href='https://adwaadroub.com/'
							target='_blank'
							rel='noreferrer'>
							{" "}
							مؤسسة أضواء الدروب
						</a>{" "}
						للخدمات الإدارية والتسويقية بالتعاون مع{" "}
						<a
							className='text-[#d0d0d0c2] hover:text-color_04  font-bold'
							href='https://layersads.com/'
							target='_blank'
							rel='noreferrer'>
							{" "}
							وكالة لايرز{" "}
						</a>
						للتسويق والإعلان
					</span>
				</h6>
			</div>
		</footer>
	);
};

export default Footer;
