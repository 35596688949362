import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const PagesThunk = createAsyncThunk(
	"PagesThunk",
	async (_, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.get("https://api.alausra.sa/api/public/pages");

			return res?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
