import { createAsyncThunk } from "@reduxjs/toolkit";
import axios from "axios";

export const MainPageSectionsStatusThunk = createAsyncThunk(
	"MainPageSectionsStatusThunk",
	async (_, ThunkApi) => {
		const { rejectWithValue } = ThunkApi;
		try {
			const res = await axios.get("https://api.alausra.sa/api/main_page");

			return res?.data;
		} catch (error) {
			return rejectWithValue(error.response.data);
		}
	}
);
